/** @jsx jsx */

import { useContext } from 'react';
import { Flex, jsx, Box } from 'theme-ui';
import { useLocation } from '@reach/router';
import { TwitterShareButton, FacebookShareButton } from 'react-share';

import MessageContext from '~/contexts/MessageContext';
import TwitterIcon from '~/assets/images/icons/twitter-share.svg';
import FacebookIcon from '~/assets/images/icons/facebook-share.svg';

import CopyIcon from '~/assets/images/icons/copy-link.svg';

export default function FlowSocialButtons({ ...props }) {
  const [, setMessage] = useContext(MessageContext);

  const { href } = useLocation();
  const encoded = encodeURI(href);
  const copyToClipboard = () => {
    const thatDoc = typeof document !== 'undefined' ? document : undefined;
    const thatWindow = typeof window !== 'undefined' ? window : undefined;
    const inputc = thatDoc.querySelector('#copy-url').appendChild(document.createElement('input'));
    inputc.value = thatWindow.location.href;
    inputc.focus();
    inputc.select();
    document.execCommand('copy');
    inputc.parentNode.removeChild(inputc);
    setMessage('Copied url to clipboard.');
  };
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        alignItems: 'center',
        mr: ['5px', 0, 0],
        width: [null, null, '10%'],
        height: 'auto',
        position: 'relative',
        mt: ['10px', '23px', '23px'],
        left: ['-10px', 0, 0],
      }}
      {...props}
    >
      {' '}
      <TwitterShareButton
        url={encoded}
        // hashtags={_.map(question.topics, (topic) => topic.toLocaleLowerCase()) || ''}
      >
        <Box
          sx={{
            height: '30px',
            width: '30px',
            marginBottom: '10px',
            '& > svg': { height: '100%', width: '100%' },
          }}
        >
          <TwitterIcon />
        </Box>
      </TwitterShareButton>
      <FacebookShareButton
        url={encoded}
        // hashtags={_.map(question.topics, (topic) => topic.toLocaleLowerCase()) || ''}
      >
        <Box
          sx={{
            height: '30px',
            width: '30px',
            marginBottom: '10px',
            '& > svg': { height: '100%', width: '100%' },
          }}
        >
          <FacebookIcon />
        </Box>
      </FacebookShareButton>
      <Box
        id="copy-url"
        role="button"
        onClick={copyToClipboard}
        sx={{
          height: '30px',
          width: '30px',
          marginBottom: '10px',
          '& > svg': { height: '100%', width: '100%' },
        }}
      >
        <CopyIcon />
      </Box>
    </Flex>
  );
}
