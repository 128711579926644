/** @jsx jsx */
import { Flex, Text, jsx } from 'theme-ui';
import { GatsbyImage } from 'gatsby-plugin-image';
import { DateTime } from 'luxon';
import FlowSocialButtons from '~/components/Flow/FlowSocialButtons';
import FlowPost from '~/components/Flow/FlowPost';
import FlowInterview from '~/components/Flow/FlowInterview';
import FlowListicle from '~/components/Flow/FlowListicle';
import VideoPlayer from '~/components/Generic/VideoPlayer';

const FlowPostContainer = ({ post }) => {
  const { title, augustAuthor: author, date, heroImage: mainImage, slug, type, question } = post;

  const formattedDate = DateTime.fromISO(date);
  const { year, month, day } = formattedDate.c;

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        width: '100%',
        mt: ['33px'],
        alignItems: ['center', 'flex-start', 'flex-start'],
        height: ['100%'],
      }}
    >
      {post.video && !post.isBottomVideo ? (
        <Flex
          sx={{
            justifyContent: 'center',
            position: 'relative',
            width: ['100%', '90%', '90%'],
            video: {
              height: '100%',
              width: ['100%', '100%', '90%'],
              ml: [0, '10%', '10%'],
            },
          }}
        >
          <VideoPlayer url={post.video.file.url} />
        </Flex>
      ) : (
        <Flex
          sx={{
            alignItems: 'center',
            width: ['100%', '90%', '90%'],
            justifyContent: 'flex-start',
            height: ['30%'],
            ml: [0, '10%', '10%'],
            '& > .gatsby-image-wrapper': {
              height: '100%',
              width: ['100%', '100%', '100%'],
              maxWidth: ['690px', '710px'],
            },
          }}
        >
          {mainImage && <GatsbyImage image={mainImage.gatsbyImageData} />}
        </Flex>
      )}
      {/* Text & Content / Socials Container */}
      <Flex
        sx={{
          width: ['100%'],
          height: '100%',
          justifyContent: ['flex-start'],
        }}
      >
        <FlowSocialButtons sx={{ display: ['none', 'flex', 'flex'] }} />
        {/* Content Container */}
        <Flex
          sx={{
            flexDirection: 'column',
            width: ['100%', '90%', '90%'],
            maxWidth: ['690px', '1060px'],
          }}
        >
          <Text
            as="h1"
            sx={{
              fontWeight: 700,
              fontSize: [12, 13, 14],
              fontFamily: 'merriweather',
              fontStyle: 'italic',
              mt: ['23px'],
              width: '100%',
              textAlign: ['left', 'left', 'left'],
            }}
          >
            {question ?? title}
          </Text>
          {/* Date / Authour Container */}
          <Flex
            sx={{
              fontWeight: 300,
              fontSize: [4, 4, 5],
              color: 'black',
              mt: ['1rem'],
            }}
          >
            <Text>{author}</Text>
            <Text sx={{ mx: ['0.6rem'] }}> | </Text>
            <Text>
              {month}.{day}.{year}
            </Text>
          </Flex>
          <FlowSocialButtons sx={{ display: ['flex', 'none', 'none'], flexDirection: 'row' }} />

          {/* Main Content Containers */}
          {type === 'blog' && <FlowPost post={post} />}
          {type === 'interview' && <FlowInterview post={post} />}
          {type === 'listicle' && <FlowListicle post={post} />}
          {post.video && post.isBottomVideo ? (
            <Flex
              sx={{
                justifyContent: 'center',
                position: 'relative',
                width: ['100%', '90%', '90%'],
                video: {
                  height: '100%',
                  width: ['100%', '100%', '90%'],
                  ml: [0, '10%', '10%'],
                  mt: '3rem',
                },
              }}
            >
              <VideoPlayer url={post.video.file.url} />
            </Flex>
          ) : null}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default FlowPostContainer;
