/** @jsx jsx */
import { Flex, Button, jsx } from 'theme-ui';
import { Link } from 'gatsby';
import ShopSplashTextMarquee from '~/components/Generic/ShopSplashTextMarquee';

const UnifiedBlogLayout = ({ children }) => (
  <Flex
    sx={{
      minHeight: '100vh',
      pb: '120px',
      flexDirection: 'column',
      alignItems: 'center',
    }}
  >
    <ShopSplashTextMarquee
      textArr={['SHOP OUR FAVES']}
      symbol="•"
      backgroundColor="canary"
      fontWeight="bold"
      linkTo="/shop"
    />
    <Flex sx={{ flexDirection: 'column', width: '90%', alignItems: 'center' }}>{children}</Flex>
  </Flex>
);

export default UnifiedBlogLayout;
