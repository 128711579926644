import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Box } from 'theme-ui';
import useWindowSize from '~/hooks/components/use-window-size';

const ImageCarousel = ({ images, infiniteLoop = true, boxPDP = false, contain = false }) => {
  const { width } = useWindowSize();
  const desktop = width > 640;
  return (
    <Box
      sx={{
        '.carousel-root': {
          '.carousel': {
            '.slide': {
              background: 'none',
              position: 'relative',
              border: 'none',
              height: '100%',
              aspectRatio: '1',
            },
            '.control-dots': {
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              padding: 0,
              marginBottom: '2rem',
              '> .dot': {
                height: ['1rem', '1rem', '2rem !important'],
                width: ['1rem', '1rem', '2rem !important'],
                background: 'none',
                border: '2px solid #CF2029 !important',
                boxShadow: 'none',
                opacity: 1,
                marginRight: '0.5rem !important',
                marginLeft: '0rem !important',
                '&.selected': {
                  backgroundColor: '#CF2029',
                },
              },
            },
          },
        },
      }}
    >
      <Carousel
        showIndicators={images.length > 1}
        showStatus={false}
        showArrows={false}
        showThumbs={false}
        autoPlay={desktop}
        dynamicHeight={false}
        preventMovementUntilSwipeScrollTolerance
        swipeScrollTolerance={50}
        infiniteLoop={infiniteLoop && images.length > 1}
      >
        {images.map((image, index) => (
          <GatsbyImage
            image={image.gatsbyImageData}
            key={index}
            alt={image.title}
            style={{ height: '100%' }}
            objectFit={contain ? 'contain' : ''}
          />
        ))}
      </Carousel>
    </Box>
  );
};

ImageCarousel.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  infiniteLoop: PropTypes.bool,
};

export default ImageCarousel;
