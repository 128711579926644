/** @jsx jsx */

import React from 'react';
import { Flex, jsx, Box } from 'theme-ui';
import { GatsbyImage } from "gatsby-plugin-image";
import useWindowSize from '~/hooks/components/use-window-size';
import ImageCarousel from '~/components/Generic/ImageCarousel';

export default function FlowPost({ post }) {
  const { bodyP1, bodyP2, auxImages } = post;
  const { width: windowWidth } = useWindowSize();

  return <>
    <Box
      dangerouslySetInnerHTML={{
        __html: bodyP1.childMarkdownRemark.html,
      }}
      sx={{
        color: 'black',
        fontSize: [7, 7, 8],
        mt: ['0', '1.7rem', '0.7rem'],
        width: ['100%'],
        fontWeight: 400,
        pr: ['1.1rem', '0.7rem', '0.7rem'],
        mb: ['2rem', '5rem', 0],
        letterSpacing: '0.392857px',
        '& > p': { mb: '1.5rem', mt: ['0', null, '0.7rem'] },
        '& > h1': { fontWeight: 400, fontSize: [7, 7, 8] },
      }}
    />
    {/* Aux Images DT Container */}
    {auxImages && (
      <Flex
        sx={{
          display: ['none', 'none', 'flex'],
          my: ['4rem'],
          justifyContent: 'space-evenly',
          width: '100%',
        }}
      >
        {auxImages.map((img, idx) => (
          <Flex
            key={`${img}+${idx}`}
            sx={{
              alignItems: 'center',
              width: `${100 / auxImages.length - 4}%`,
              justifyContent: 'center',
              '& > .gatsby-image-wrapper': {
                height: '100%',
                width: ['100%'],
              },
            }}
          >
            <GatsbyImage image={img.gatsbyImageData} />
          </Flex>
        ))}
      </Flex>
    )}
    {/* Aux Images Mobile Carousel */}
    {auxImages && (
      <Box
        sx={{
          display: ['block', 'block', 'none'],
          width: windowWidth < 832 ? 'calc(100% + 1rem + 1rem)' : 'calc(140% + 1rem + 1rem)',
          position: 'relative',
          right: windowWidth < 832 ? '1rem' : '4rem',
          margin: 'auto',
          '& .carousel-slider': {
            overflow: 'hidden',
          },
          '& .slider-wrapper': {
            overflow: 'hidden',
          },
        }}
      >
        <ImageCarousel images={auxImages} />
      </Box>
    )}
    {bodyP2 && (
      <Box
        dangerouslySetInnerHTML={{
          __html: bodyP2.childMarkdownRemark.html,
        }}
        sx={{
          color: 'black',
          fontSize: [7, 7, 8],
          mt: ['0', null, '0.7rem'],
          width: ['100%', '100%', '100%'],
          fontWeight: 400,
          pr: ['1.1rem', '0.7rem', '0.7rem'],
          mb: ['2rem', '5rem', 0],
          letterSpacing: '0.392857px',
          '& > p': { mb: 0 },
          '& > h1': { fontWeight: 400, fontSize: [7, 7, 8] },
        }}
      />
    )}
  </>;
}
