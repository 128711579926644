/* eslint-disable no-nested-ternary */
/** @jsx jsx */
import React, { useState, useEffect, useContext } from 'react';
import {
  Container,
  Box,
  Text,
  Flex,
  Link as ExternalLink,
  jsx,
  useThemeUI,
  Heading,
} from 'theme-ui';
import _ from 'lodash';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useLocation } from '@reach/router';
import { TwitterShareButton } from 'react-share';
import Spacer from '~/components/Generic/Layout/Spacer';
import getRandom from '~/utils/list-methods/getRandom';
import ShopSplashTextMarquee from '~/components/Generic/ShopSplashTextMarquee';
import MessageContext from '~/contexts/MessageContext';
import TwitterIcon from '~/assets/images/icons/twitter-share.svg';
import CopyIcon from '~/assets/images/icons/copy-link.svg';
import QuestionIcon from '~/assets/images/icons/ask-question.svg';
import ArrowNext from '~/assets/images/icons/arrow-next.svg';

const AskAugustQuestionPage = ({ question, topics, relatedQuestions, page }) => {
  const [, setMessage] = useContext(MessageContext);
  const [related, setRelated] = useState([]);
  const [sourceLinks, setSourceLinks] = useState(null);
  const [sourceLinksOpen, setSourceLinksOpen] = useState(false);

  const { theme } = useThemeUI();
  const { href } = useLocation();
  const encoded = encodeURI(href);

  useEffect(() => {
    const relatedWithoutSelf = _.filter(relatedQuestions, (rel) => rel.slug !== question.slug);
    const numRelated = _.size(relatedWithoutSelf);
    if (numRelated < 3) {
      setRelated(getRandom(relatedWithoutSelf, numRelated));
    } else {
      setRelated(getRandom(relatedWithoutSelf, 3));
    }
  }, []);

  const [activeAnswer, setActiveAnswer] = useState('Ours');

  const copyToClipboard = () => {
    const thatDoc = typeof document !== 'undefined' ? document : undefined;
    const thatWindow = typeof window !== 'undefined' ? window : undefined;
    const inputc = thatDoc.querySelector('#copy-url').appendChild(document.createElement('input'));
    inputc.value = thatWindow.location.href;
    inputc.focus();
    inputc.select();
    document.execCommand('copy');
    inputc.parentNode.removeChild(inputc);
    setMessage('Copied url to clipboard.');
  };
  useEffect(() => {
    if (question.sources) {
      const sources = JSON.parse(question.sources?.raw)
        .content[0].content.filter((node) => node.nodeType === 'hyperlink')
        .map((node) => node.data.uri);
      const uniqueSourceLinks = [...new Set(sources)];
      setSourceLinks(uniqueSourceLinks);
    }
  }, []);

  return (
    <>
      <ShopSplashTextMarquee
        textArr={['SHOP OUR FAVES']}
        symbol="•"
        backgroundColor="canary"
        fontWeight="bold"
        linkTo="/shop"
      />
      <Container variant="fullwidth">
        <Container
          sx={{
            backgroundColor: activeAnswer === 'Ours' ? 'canary' : 'unset',
            position: 'relative',
          }}
        >
          <Flex
            sx={{
              justifyContent: 'center',
              mt: '100px',
              mb: ['25px', '50px', '75px'],
              '& > .gatsby-image-wrapper': {
                width: ['350px', '500px', '1000px'],
              },
            }}
          >
            <GatsbyImage
              image={question?.heroImage?.gatsbyImageData}
              alt={question?.heroImage?.title}
            />
          </Flex>
          <Flex
            sx={{
              color: 'alizarinCrimson',
              position: 'relative',
            }}
          >
            <Flex
              sx={{
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <TwitterShareButton
                url={encoded}
                hashtags={_.map(question.topics, (topic) => topic.toLocaleLowerCase()) || ''}
              >
                <Box
                  sx={{
                    height: '30px',
                    width: '30px',
                    marginBottom: '10px',
                    '& > svg': { height: '100%', width: '100%' },
                  }}
                >
                  <TwitterIcon />
                </Box>
              </TwitterShareButton>
              <Box
                id="copy-url"
                role="button"
                onClick={copyToClipboard}
                sx={{
                  height: '30px',
                  width: '30px',
                  marginBottom: '10px',
                  '& > svg': { height: '100%', width: '100%' },
                }}
              >
                <CopyIcon />
              </Box>
              <Box
                as={ExternalLink}
                href="mailto:hello@itsaugust.co?subject=I have a question for August"
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  height: '30px',
                  width: '30px',
                  '& > svg': { height: '100%', width: '100%' },
                }}
              >
                <Box
                  sx={{
                    height: '30px',
                    width: '30px',
                    marginBottom: '10px',
                    '& > svg': { height: '100%', width: '100%' },
                  }}
                >
                  <QuestionIcon />
                </Box>
              </Box>
            </Flex>
            <Container>
              <Text
                as="h1"
                sx={{
                  fontSize: [10, 12, 15],
                  fontWeight: 800,
                  color: 'black',
                }}
              >
                {question.question}
              </Text>
              <Spacer height="15px" />
              {question.medicalBoardMember && (
                <Text sx={{ textTransform: 'uppercase', fontSize: [2, 3, 4] }}>
                  {`Verified by ${question.medicalBoardMember?.name}`}
                </Text>
              )}
              <Spacer height="15px" />
              {question.triggerWarning && (
                <Text
                  sx={{
                    color: 'canary',
                    backgroundColor: 'alizarinCrimson',
                    fontWeight: 700,
                    width: 'max-content',
                    textTransform: 'uppercase',
                  }}
                >{`**WARNING: ${question.triggerWarning}**`}</Text>
              )}
              <Spacer height="25px" />
              <Flex
                sx={{
                  '& > div': {
                    cursor: "url('/images/blood.svg'), pointer",
                    padding: '5px 10px',
                    borderRadius: '12px',
                    fontSize: [2, 3, 4],
                  },
                }}
              >
                <Box
                  sx={{
                    marginRight: '25px',
                    fontWeight: `${activeAnswer === 'Ours' ? 'extrabold' : 'body'}`,
                    textTransform: 'uppercase',
                    background: `${
                      activeAnswer === 'Ours' ? theme.colors.alizarinCrimson : 'transparent'
                    }`,
                    color: `${
                      activeAnswer === 'Ours' ? theme.colors.canary : theme.colors.alizarinCrimson
                    }`,
                  }}
                  onClick={() => setActiveAnswer('Ours')}
                >
                  Our Answer
                </Box>
                {question.medicalAnswer ? (
                  <Box
                    sx={{
                      fontWeight: `${activeAnswer === 'Medical' ? 'bold' : 'body'}`,
                      textTransform: 'uppercase',
                      background: `${
                        activeAnswer === 'Medical' ? theme.colors.alizarinCrimson : 'transparent'
                      }`,
                      color: `${
                        activeAnswer === 'Medical'
                          ? theme.colors.canary
                          : theme.colors.alizarinCrimson
                      }`,
                    }}
                    onClick={() => setActiveAnswer('Medical')}
                  >
                    What our doctors say
                  </Box>
                ) : null}
              </Flex>
              <Spacer height="10px" />
              {question.ourAnswer || question.medicalAnswer ? (
                <Box>
                  {activeAnswer === 'Ours' ? (
                    <>
                      {question.augustAuthor && <Text>By {question.augustAuthor}</Text>}
                      <Box
                        sx={{
                          fontSize: [10, 12, 15],
                          '& > p > a': { fontWeight: 'bold' },
                          '& > p > img': { maxWidth: '100vw' },
                        }}
                        dangerouslySetInnerHTML={{
                          __html: question.ourAnswer.childMarkdownRemark.html,
                        }}
                      />
                    </>
                  ) : activeAnswer === 'Medical' && question.medicalAnswer ? (
                    <>
                      {question.augustAuthor && !question.medicalBoardMember && (
                        <Text>By {question.augustAuthor}</Text>
                      )}
                      <Box
                        sx={{
                          fontSize: [10, 12, 15],
                          '& > p > a': { fontWeight: 'bold' },
                          '& > p > img': { maxWidth: '100vw' },
                        }}
                        dangerouslySetInnerHTML={{
                          __html: question.medicalAnswer.childMarkdownRemark.html,
                        }}
                      />
                    </>
                  ) : null}
                </Box>
              ) : null}
              <Box />

              {sourceLinks && (
                <Flex
                  sx={{
                    mt: [3, 4, 4],
                    height: 'auto',
                    flexDirection: 'column',
                  }}
                >
                  <Flex
                    sx={{
                      mb: ['1rem'],
                      alignItems: 'center',
                      cursor: "url('/images/blood.svg'), pointer",
                    }}
                    onClick={() => setSourceLinksOpen(!sourceLinksOpen)}
                  >
                    <Heading sx={{ textTransform: 'uppercase' }}>
                      Source{sourceLinks.length > 1 ? 's' : ''}
                    </Heading>
                    <Flex
                      sx={{
                        ml: ['10px'],
                        transform: sourceLinksOpen ? 'rotate(-90deg)' : 'rotate(90deg)',
                        mt: ['1px', null, '5px'],
                        width: ['10px'],
                        transition: 'all 0.3s',
                      }}
                    >
                      <ArrowNext />
                    </Flex>
                  </Flex>
                  {sourceLinksOpen &&
                    sourceLinks.map((source, idx) => (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        key={`${source}+${idx}`}
                        href={source}
                      >
                        <Text sx={{ my: ['5px'] }}>{source}</Text>
                      </a>
                    ))}
                </Flex>
              )}
            </Container>
          </Flex>

          <Spacer height={['50px', '75px', '100px']} />
        </Container>
      </Container>
    </>
  );
};
export default AskAugustQuestionPage;
