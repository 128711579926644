/** @jsx jsx */
import React from 'react';
import { Flex, Text, jsx, Box } from 'theme-ui';
import { GatsbyImage } from 'gatsby-plugin-image';

export default function FlowListicle({ post }) {
  const { listicleItems, bodyP1, bodyP2 } = post;
  return (
    <>
      {bodyP1 && (
        <Box
          dangerouslySetInnerHTML={{
            __html: bodyP1.childMarkdownRemark.html,
          }}
          sx={{
            color: 'black',
            fontSize: [7, 7, 8],
            mt: ['0', '1.7rem', '0.7rem'],
            width: ['100%'],
            fontWeight: 400,
            pr: ['1.1rem', '0.7rem', '0.7rem'],
            mb: ['2rem', '5rem', 0],
            letterSpacing: '0.392857px',
            '& > p': { mb: '1.5rem', mt: ['0', null, '0.7rem'] },
            '& > h1': { fontWeight: 400, fontSize: [7, 7, 8] },
          }}
        />
      )}
      {listicleItems &&
        listicleItems.map((item, idx) => (
          <Flex
            key={`${item.title}-${idx}`}
            sx={{ flexDirection: 'column', alignItems: 'flex-start' }}
          >
            <Text as="h1" sx={{ mt: '27px' }}>
              {item.title}
            </Text>
            {item.mainImage && (
              <Flex
                sx={{
                  mt: '27px',
                  alignItems: 'flex-start',
                  width: '100%',
                  justifyContent: 'flex-start',
                  '& > .gatsby-image-wrapper': {
                    height: '100%',
                    width: ['100%', '70%', '60%'],
                    maxWidth: ['500px', 'none', 'none'],
                  },
                }}
              >
                <GatsbyImage image={item.mainImage.gatsbyImageData} alt={item.mainImage.title} />
              </Flex>
            )}
            <Box
              dangerouslySetInnerHTML={{
                __html: item.description.childMarkdownRemark.html,
              }}
              sx={{
                color: 'black',
                fontSize: [7, 7, 8],
                mt: ['0', null, '0.7rem'],
                width: ['100%'],
                fontWeight: 400,
                pr: ['1.1rem', '0.7rem', '0.7rem'],
                mb: ['2rem', '5rem', 0],
                '& > p': { mb: 0 },
              }}
            />
          </Flex>
        ))}
      {bodyP2 && (
        <Box
          dangerouslySetInnerHTML={{
            __html: bodyP2.childMarkdownRemark.html,
          }}
          sx={{
            color: 'black',
            fontSize: [7, 7, 8],
            mt: ['0', '1.7rem', '0.7rem'],
            width: ['100%'],
            fontWeight: 400,
            pr: ['1.1rem', '0.7rem', '0.7rem'],
            mb: ['2rem', '5rem', 0],
            letterSpacing: '0.392857px',
            '& > p': { mb: '1.5rem', mt: ['0', null, '0.7rem'] },
            '& > h1': { fontWeight: 400, fontSize: [7, 7, 8] },
          }}
        />
      )}
    </>
  );
}
