/* eslint-disable no-nested-ternary */
import React from 'react';
import { graphql, Link } from 'gatsby';
import { Button, Flex } from 'theme-ui';
import Layout from '~/components/Layout';
import Metadata from '~/components/Metadata';
import AskAugustQuestion from '~/components/AskAugust/Question';
import FlowPostContainer from '~/components/Flow/FlowPostContainer';
import UnifiedBlogLayout from '~/components/UnifiedBlog/UnifiedBlogLayout';

const AskAugustQuestionTemplate = ({ data }) => {
  const {
    question,
    topics: { nodes: topicNodes },
    relatedQuestions: { nodes: relatedQuestionNodes },
    page,
  } = data;
  const allPostSlugs = data.allQuestions.edges.map((e) => e.node.slug);
  const currentIndexSlug = allPostSlugs.indexOf(question.slug);

  return (
    <Layout>
      <Metadata
        title={question.metaTitle ?? question.question}
        description={
          question.metaDescription
            ? question.metaDescription
            : question.previewText ?? question.question
        }
        image={`https:${question?.heroImage?.fluid?.src ?? null}`}
        meta={[
          {
            name: `twitter:image`,
            content: `https:${question?.heroImage?.fluid?.src ?? null}`,
          },
        ]}
      />
      {question.isBlogPost ? (
        <UnifiedBlogLayout>
          <FlowPostContainer post={question} />
        </UnifiedBlogLayout>
      ) : (
        <AskAugustQuestion
          question={question}
          topics={topicNodes}
          relatedQuestions={relatedQuestionNodes}
          page={page}
        />
      )}
      <Flex sx={{ width: '100%', justifyContent: 'center', mb: '12px' }}>
        {currentIndexSlug > 0 && (
          <Button
            as={Link}
            to={`/ask-august/questions/${allPostSlugs[currentIndexSlug - 1]}`}
            sx={{ mx: '6px' }}
            variant="secondary"
          >
            {'<'} Previous Article
          </Button>
        )}
        {currentIndexSlug < allPostSlugs.length - 1 && (
          <Button
            to={`/ask-august/questions/${allPostSlugs[currentIndexSlug + 1]}`}
            as={Link}
            sx={{ mx: '6px' }}
            variant="secondary"
          >
            Next Article >
          </Button>
        )}
      </Flex>
    </Layout>
  );
};

export const query = graphql`
  query AskAugustQuestionQuery($slug: String, $topics: [String]) {
    question: contentfulAskAugustQuestion(slug: { eq: $slug }) {
      ...AskAugustQuestionFragment
    }
    topics: allContentfulAskAugustTopic(filter: { title: { in: $topics } }) {
      nodes {
        ...AskAugustTopicFragment
      }
    }
    relatedQuestions: allContentfulAskAugustQuestion(filter: { topics: { in: $topics } }) {
      nodes {
        ...AskAugustQuestionFragment
      }
    }
    page: contentfulPage(slug: { eq: "ask-august" }) {
      ...ContentPageFragment
    }
    allQuestions: allContentfulAskAugustQuestion {
      edges {
        node {
          slug
        }
      }
    }
  }
`;

export default AskAugustQuestionTemplate;
